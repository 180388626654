import * as React from "react"
import ArchitectIcon from "../icons/architectIcon"
import CodeIcon from "../icons/codeIcon"
import ProductIcon from "../icons/productIcon"
import ProjectIcon from "../icons/projectIcon"

const Ups = () => (
  <section className="container mx-auto mb-16 text-center print:hidden">
    <div className="grid grid-cols-2 lg:grid-cols-4 justify-around">
      <a href="#services" className=" hover:underline">
        <CodeIcon className="w-24 h-24 lg:w-32 lg:h-32 p-4 mx-auto" />
        <h2 className="text-lg">
          Software
          <br />
          Development
        </h2>
      </a>
      <a href="#services" className=" hover:underline">
        <ArchitectIcon className="w-24 h-24 lg:w-32 lg:h-32 p-4 mx-auto" />
        <h2 className="text-lg">
          Solution
          <br />
          Architecture
        </h2>
      </a>
      <a href="#services" className=" hover:underline">
        <ProjectIcon className="w-24 h-24 lg:w-32 lg:h-32  p-4 mx-auto" />
        <h2 className="text-lg">
          Project
          <br />
          Management
        </h2>
      </a>
      <a href="#services" className=" hover:underline">
        <ProductIcon className="w-24 h-24 lg:w-32 lg:h-32  p-4 mx-auto" />
        <h2 className="text-lg">
          Product
          <br />
          Ownership
        </h2>
      </a>
    </div>
  </section>
)

export default Ups
